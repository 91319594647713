<template>
  <div>
    <div v-if="tabs_mode">
      <b-tabs content-class="mt-3">
        <b-tab
          :title="
            $getVisibleNames(
              'teaching.evaluationcriteriamacro',
              false,
              'Criterio de Evaluación Macro'
            )
          "
          active
        >
          <SentenceForm
            :first_content="evaluation_criteria_macro.action"
            :second_content="evaluation_criteria_macro.content"
            :third_content="evaluation_criteria_macro.condition"
            :full_sentence="evaluation_criteria_macro.full_sentence"
            :verb="evaluation_criteria_macro.verb"
            third_content_comment
            @update="slotUpdateSentence"
            :allows_crud="true"
          ></SentenceForm>
          <b-form-group
            v-if="matter && !matter.automatic_achievement_weighing"
            label="Ponderación:"
            label-cols="0"
            label-cols-lg="3"
            label-for="input-weighing"
            :description="`Ponderación con respecto al Resultado de Aprendizaje.`"
            class="m-0 mb-3"
          >
            <b-form-input
              id="input-weighing"
              type="number"
              min="0"
              v-model="$v.evaluation_criteria_macro.weighing.$model"
              :state="validateState('weighing')"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-weighing-feedback"
              >Este campo es obligatorio y no debe exceder
              {{ WeighingAvailable }}.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-tab>
        <!-- <b-tab title="Detalles">
          <b-form-group
            v-if="matter && !matter.automatic_achievement_weighing"
            label="Ponderación"
            label-cols="0"
            label-cols-lg="3"
            label-for="input-weighing"
            :description="`Ponderación con respecto al Resultado de Aprendizaje.`"
            class="my-0"
          >
            <b-form-input
              id="input-weighing"
              type="number"
              min="0"
              v-model="$v.evaluation_criteria_macro.weighing.$model"
              :state="validateState('weighing')"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-weighing-feedback"
              >Este campo es obligatorio y no debe exceder
              {{ WeighingAvailable }}.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            label="Orden"
            label-cols="0"
            label-cols-lg="3"
            label-for="input-order"
            :description="`Orden en que se mostrará el ${$getVisibleNames(
              'teaching.evaluationcriteriamacro',
              false,
              'Criterio de Evaluación Macro'
            )}.`"
            class="my-0"
          >
            <b-form-input
              id="input-order"
              type="number"
              min="1"
              v-model="$v.evaluation_criteria_macro.order.$model"
              :state="validateState('order')"
              aria-describedby="input-order-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-order-feedback"
              >Este campo es obligatorio y no debe ser menor a
              1.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-tab> -->
      </b-tabs>
    </div>
    <div v-else>
      <h4>
        <span v-if="isNaN(evaluation_criteria_macro.id)">Crear</span>
        <span v-else>Modificar</span>
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamacro",
            false,
            "Criterio de Evaluación Macro"
          )
        }}
        <!-- Criterio de Evaluación -->
        <div class="mx-2"></div>
        <div>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="sm"
            label="Ponderación"
            label-for="input-weighing"
          >
            <b-form-input
              type="number"
              id="input-weighing"
              v-model="$v.evaluation_criteria_macro.weighing.$model"
              :state="validateState('weighing')"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-weighing-feedback"
              >Este campo es obligatorio y debe estar entre 0 y
              {{ WeighingAvailable }}.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </h4>
      <div>
        <SentenceForm
          :first_content="evaluation_criteria_macro.action"
          :second_content="evaluation_criteria_macro.content"
          :third_content="evaluation_criteria_macro.condition"
          :full_sentence="evaluation_criteria_macro.full_sentence"
          :verb="evaluation_criteria_macro.verb"
          third_content_comment
          @update="slotUpdateSentence"
          :allows_crud="true"
        ></SentenceForm>
      </div>
    </div>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(evaluation_criteria_macro.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="remove"
          >Eliminar
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamacro",
              false,
              "Criterio de Evaluación Macro"
            )
          }}</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save"
          >Guardar
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamacro",
              false,
              "Criterio de Evaluación Macro"
            )
          }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minValue, maxValue } from "vuelidate/lib/validators";

export default {
  name: "EvaluationCriterialMacroForm",
  mixins: [validationMixin],
  components: {
    SentenceForm: () => import("@/components/reusable/SentenceForm"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    EvaluationCriteriaMacro: {
      type: Object,
    },
    max_weighing: {
      type: Number,
      default: 100,
    },
    order: {
      type: Number,
      default: 1,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    tabs_mode: {
      type: Boolean,
      default: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      evaluation_criteria_macro: {
        id: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.id
          : generateUniqueId(),
        order: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.order
          : this.order,
        action: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.action
          : "",
        content: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.content
          : "",
        condition: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.condition
          : "",

        full_sentence: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.full_sentence
          : null,
        matter: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.matter
          : this.matter_id,
        weighing: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.weighing
          : 0,
        verb: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.verb
          : null,
        methodological_strategies: this.EvaluationCriteriaMacro
          ? this.EvaluationCriteriaMacro.methodological_strategies
          : [],
      },
      base_sentence_toggle: this.EvaluationCriteriaMacro
        ? this.EvaluationCriteriaMacro.content
          ? true
          : false
        : true,
    };
  },
  validations() {
    return {
      evaluation_criteria_macro: {
        order: {
          required,
          minValue: minValue(1),
        },
        action: { required },
        content: { required },
        condition: { required },
        weighing: {
          required,
          minValue: minValue(0),
          maxVlaue: maxValue(this.WeighingAvailable),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      studyUnits: names.STUDY_UNITS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      matters: names.MATTERS,
      //   raMacros: names.RA_MACROS,
    }),
    matter() {
      if (!this.matter_id) return null;
      return this.matters.find((x) => x.id == this.matter_id);
    },
    matterStudyUnits() {
      if (!this.matter_id) return [];
      return this.studyUnits.filter((x) => x.matter == this.matter_id);
    },
    evaluationCriteriaMacroList() {
      if (!this.matter_id) return [];
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    WeighingAvailable() {
      let weighing = 100;
      this.evaluationCriteriaMacroList.forEach((element) => {
        if (element.id != this.evaluation_criteria_macro.id) {
          weighing -= element.weighing;
        }
      });
      this.matterStudyUnits.forEach((element) => {
        weighing -= element.weighing;
      });
      return weighing;
    },
    // raMacro() {
    //   if (!this.matter_id) return null;
    //   return this.raMacros.find((x) => x.matter == this.matter_id);
    // },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.evaluation_criteria_macro[key];
      return $dirty ? !$error : null;
    },
    slotUpdateSentence(sentence) {
      this.evaluation_criteria_macro.verb = sentence.verb;
      this.evaluation_criteria_macro.action = sentence.first_content;
      this.evaluation_criteria_macro.content = sentence.second_content;
      this.evaluation_criteria_macro.condition = sentence.third_content;
      this.evaluation_criteria_macro.full_sentence = sentence.full_sentence;
      this.base_sentence_toggle = sentence.base_sentence_toggle;
      this.$emit("changed", this.evaluation_criteria_macro);
    },

    save() {
      if (this.base_sentence_toggle) {
        this.$v.evaluation_criteria_macro.$touch();
        if (this.$v.evaluation_criteria_macro.$anyError) {
          return;
        }
        this.evaluation_criteria_macro.full_sentence = String(
          this.evaluation_criteria_macro.action +
            " " +
            this.evaluation_criteria_macro.content +
            " " +
            this.evaluation_criteria_macro.condition
        );
        if (isNaN(this.evaluation_criteria_macro.id))
          this.createEvaluationCriteriaMacro();
        else this.updateEvaluationCriteriaMacro();
      } else {
        if (
          this.evaluation_criteria_macro.full_sentence ||
          !this.evaluation_criteria_macro.full_sentence.trim() == ""
        ) {
          if (isNaN(this.evaluation_criteria_macro.id))
            this.createEvaluationCriteriaMacro();
          else this.updateEvaluationCriteriaMacro();
        }
      }
    },
    remove() {
      if (!this.allows_crud && !isNaN(this.evaluation_criteria_macro.id)) {
        this.$emit("delete", this.evaluation_criteria_macro);
        return;
      }
      this.$store
        .dispatch(
          names.DELETE_EVALUATION_CRITERIA_MACRO,
          this.evaluation_criteria_macro.id
        )
        .then(() => {
          if (this.matter_id) {
            this.$restful
              .Get(
                `teaching/update_ra_micro_ec_macro_weighing/?matter=${this.matter_id}`
              )
              .then(() => {
                this.$store.dispatch(
                  names.FETCH_EVALUATION_CRITERIAS_MACROS,
                  this.matter_id
                );
                this.$store.dispatch(names.FETCH_STUDY_UNITS, this.matter_id);
              });
          }
        });
      this.$emit("deleted", this.evaluation_criteria_macro);
    },
    createEvaluationCriteriaMacro() {
      if (!this.allows_crud) {
        this.$emit("create", this.evaluation_criteria_macro);
        return;
      }
      this.$store
        .dispatch(
          names.POST_EVALUATION_CRITERIA_MACRO,
          this.evaluation_criteria_macro
        )
        .then(() => {
          if (this.matter_id) {
            this.$restful
              .Get(
                `teaching/update_ra_micro_ec_macro_weighing/?matter=${this.matter_id}`
              )
              .then(() => {
                this.$store.dispatch(
                  names.FETCH_EVALUATION_CRITERIAS_MACROS,
                  this.matter_id
                );
                this.$store.dispatch(names.FETCH_STUDY_UNITS, this.matter_id);
              });
          }
        });
      this.$emit("created", this.evaluation_criteria_macro);
    },
    updateEvaluationCriteriaMacro() {
      if (!this.allows_crud) {
        this.$emit("update", this.evaluation_criteria_macro);
        return;
      }
      this.$store.dispatch(
        names.UPDATE_EVALUATION_CRITERIA_MACRO,
        this.evaluation_criteria_macro
      );
      this.$emit("updated", this.evaluation_criteria_macro);
    },
  },
};
</script>

<style scoped>
h4,
.h4 {
  text-align: center;
}
</style>